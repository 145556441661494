<template>
  <b-modal
    v-model="isOpen"
    size="lg"
    centered
    :hide-footer="mode === 'view'"
    class="approve-modal"
    @hide="handleHideModal"
  >
      <template #modal-title>
      <div class="d-flex approve-modal__header">
        <b-avatar
          variant="info"
          size="30px"
          :text="getNameInitials(employee?.name ?? '')"
          :src="employee?.photo"
        />
        <div class="approve-modal__headline">{{ employee?.name }}</div>
          <div class="font-size-14 ml-auto" v-if="purchase?.purchase_category?.title == 'Bimicoin Expenditure' && $hasAccess(['accountant'])">
              Available to spend <strong>{{ employee?.bimicoins?.balance }} bimicoins</strong>
          </div>
      </div>
    </template>
    <div class="d-flex align-items-center justify-content-between" style="gap: 20px">
      <div class="approve-modal__title">
        {{ purchase?.purchase_category?.title }}
      </div>
      <div v-if="purchase" class="approve-modal__price">
        {{ numberWithSpaces(purchase?.price) }} {{ purchase?.currency }}
      </div>
    </div>
    <div
      class="approve-modal__description"
      v-html="linkify(purchase?.description ?? '')"
    />
    <a
      v-if="purchase?.link"
      :href="purchase?.link"
      target="_blank"
      class="approve-modal__link"
    >
      {{ purchase?.link }}
    </a>
    <div
      v-if="purchase?.attachments?.length"
      class="approve-modal__files files"
    >
      <span class="files__title">Files</span>
      <div class="files__list">
        <div
          v-for="attachment in purchase.attachments"
          :key="attachment.id"
          class="files__item text-primary"
          @click="handleDownloadAttachment(attachment)"
        >
          <i class="bx bxs-download" />
          <div>{{ attachment.file_name }}</div>
        </div>
      </div>
    </div>
    <div
      class="approve-modal__comments"
      :class="{ 'approve-modal__comments_without-border': mode !== 'approve' }"
    >
      <div
        v-for="comment in comments"
        :key="comment.id"
        class="comment"
      >
        <div class="d-flex align-items-start justify-content-between">
          <div class="comment__user user">
            <div class="user__avatar">
              <b-avatar
                variant="info"
                size="30px"
                :text="getNameInitials(comment?.employee?.name)"
                :src="comment?.employee?.photo"
              />
              <div
                v-if="isApproved(comment?.status) || isDeclined(comment?.status)"
                class="user__approve-icon"
                :class="{
                  'user__approve-icon_checked': isApproved(comment?.status),
                  'user__approve-icon_declined': isDeclined(comment?.status)
                }"
              >
                <i v-if="isApproved(comment?.status)" class="bx bx-check" />
                <i v-if="isDeclined(comment?.status)" class="bx bx-x" />
              </div>
            </div>
            <div class="user__name">{{ comment?.employee?.name }}</div>
          </div>
          <div class="comment__date">
            {{ comment?.created_at? $moment(comment?.created_at).format('DD.MM.YYYY hh:mm') : '' }}
          </div>
        </div>
        <div
          v-if="comment?.comment"
          class="comment__text"
        >
          {{ comment.comment }}
        </div>
      </div>
    </div>
    <b-form-group
      v-if="mode === 'approve'"
      label="Your Comment"
      label-for="comment"
      class="approve-modal__textarea"
    >
      <textarea
        type="text"
        v-model="form.comment"
        id="comment"
        rows="3"
        class="form-control"
      />
    </b-form-group>
    <template slot="modal-footer">
      <div
        v-if="mode !== 'view'"
        class="d-flex justify-content-between w-100"
      >
        <b-button
          variant="danger"
          @click="handleDecline"
        >
          Decline
        </b-button>
        <b-button
          v-if="mode === 'approve'"
          variant="success"
          @click="handleApprove"
        >
          Approve
        </b-button>
        <b-button
          v-else-if="mode === 'pay'"
          variant="primary"
          @click="handlePay"
        >
          Pay
        </b-button>
        <b-button
          v-else-if="mode === 'execute'"
          variant="primary"
          @click="handleExecute"
        >
          Execute
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { getNameInitials } from '@/utils/user';
import { numberWithSpaces } from '@/utils/numbers';

export default {
  props: {
    value: Boolean,
    purchase: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: String,
      default: 'approve',
      validator(value) {
        return ['approve', 'pay', 'execute', 'view'].includes(value)
      }
    }
  },
  data() {
    return {
      form: {
        comment: ''
      }
    }
  },
  methods: {
    getNameInitials,
    numberWithSpaces,
    createDeclineMessageConfirm() {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['d-flex', 'justify-content-center', 'flex-column'] }, [
          h('span', { class: 'bx bx bx-error text-danger mb-4', style: 'font-size: 70px; text-align: center;' }),
          h('p', { class: ['text-center'], style: 'font-size: 14px;' }, [
          'Are you sure you want to ',
          h('strong', 'Decline'),
          '? This action ',
          h('strong', 'can`t be undone'),
          ', and the employee will be required to submit a new requisition.'
        ]),
      ])
      return [messageVNode]
    },
    handleDecline() {
      this.$bvModal.msgBoxConfirm(this.createDeclineMessageConfirm(), {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then((value) => {
            if (value) {
              this.$emit('onSubmit', { ...this.form, status: 'declined' });
              this.isOpen = false;
            }
          }).catch(e => e)
    },
    handleApprove() {
      this.$emit('onSubmit', { ...this.form, status: 'approved' });
      this.isOpen = false;
    },
    handleHideModal() {
      this.form.comment = '';
    },
    handlePay() {
      this.$emit('onPay', this.purchase);
    },
    handleExecute() {
      this.$emit('onExecute', this.purchase);
    },
    handleDownloadAttachment(attachment) {
      this.$emit('onDownloadAttachment', attachment);
    },
    isApproved(status) {
      return status === 'approved' || status === 'done' || status === 'paid';
    },
    isDeclined(status) {
      return status === 'declined';
    },
    linkify(text) {
      // eslint-disable-next-line
      const urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      return text.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank">${url}</a>`;
      });
    },
  },
  computed: {
    isOpen: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    },
    employee() {
      return this.purchase?.employee ?? {};
    },
    comments() {
      return this.purchase?.purchase_statuses ?? [];
    },
  }
}
</script>

<style lang="scss">
    .modal-title{
        width: 100%;
    }
    </style>

<style lang="scss" scoped>
    .modal-title{
        width: 100%;
    }
.approve-modal {
  &__header {
    gap: 10px;
    display: flex;
    align-items: center;
  }
  &__headline {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    font-style: normal;
  }
  &__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__price {
    color: #28A745;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    white-space: nowrap;
  }
  &__description {
    font-size: 13px;
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    word-break: break-word;
    white-space: pre-wrap;
  }
  &__link {
    width: 100%;
    color: #556EE6;
    font-size: 13px;
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: inline-block;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__files {
    margin-top: 15px;
  }
  &__comments {
    margin: 20px -16px 0px -16px;
    border-bottom: 1px solid #E3E4E8;

    &_without-border {
      border: none;
    }
  }
  &__textarea {
    margin-top: 20px;
  }
}
.comment {
  padding: 20px 16px;
  border-top: 1px solid #E3E4E8;

  &__date {
    font-size: 13px;
    color: #74788D;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__text {
    font-size: 13px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    word-break: break-word;
    white-space: pre-wrap;
  }
}
.user {
  gap: 10px;
  display: flex;
  align-items: center;

  &__avatar {
    width: 30px;
    height: 30px;
    position: relative;
  }

  &__approve-icon {
    top: 0px;
    right: 0px;
    width: 12px;
    height: 12px;
    display: flex;
    border-radius: 50%;
    position: absolute;
    align-items: center;
    justify-content: center;

    &_checked {
      background: #28A745;
    }

    &_declined {
      background: #DC3545;
    }

    i {
      color: #fff;
      font-size: 12px;
    }
  }

  &__name {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
}
.files {
  &__title {
    font-weight: 500;
    margin-bottom: 5px;
  }
  &__list {
    display: flex;
    row-gap: 2px;
    flex-direction: column;
  }
  &__item {
    display: grid;
    column-gap: 5px;
    cursor: pointer;
    align-items: center;
    grid-template-columns: 16px 1fr;

    i {
      font-size: 16px;
    }

    div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
