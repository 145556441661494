<template>
  <b-modal
    v-model="isOpen"
    size="md"
    :title="isCreateMode ? 'New Requisition' : 'Edit Requisition'"
    centered
    title-class="font-22"
    class="purchase-form"
    @hide="handleHideModal"
  >
    <b-form-group
      class="required"
      label="Type"
      label-for="type"
    >
      <multiselect
        v-model="form.type"
        id="type"
        class="app-multiselect"
        :class="{ 'is-invalid': $v.form.type.$error }"
        :options="typeOptions"
        :show-labels="false"
        :allow-empty="false"
        track-by="id"
        label="label"
        placeholder="Choose Type"
        @input="handleTypeChange"
      />
    </b-form-group>
    <b-form-group
      class="required"
      label="Category"
      label-for="category"
    >
      <multiselect
        v-model="form.category"
        id="category"
        class="app-multiselect"
        :class="{ 'is-invalid': $v.form.category.$error }"
        :options="filteredCategories"
        :show-labels="false"
        :allow-empty="false"
        track-by="id"
        label="title"
        placeholder="Choose Category"
      />
    </b-form-group>
    <div class="d-flex gap-3 mb-3" v-if="isSubmitButtonDisabled">
      <div class="d-flex align-items-center mr-2">
        <i class='bx bx-error font-size-22 text-warning'></i>
      </div>
      <span class="text-muted">
          Unfortunately, this category is not available during the closing period of the month. Try to create on the 1st of the next month.
      </span>
    </div>
    <div class="mb-3" v-if="!$v.form.category.requiredUniqCategory">
      <span class="text-danger">Unfortunately, you already have a request for this category for the current month.</span>
    </div>
    <div v-if="form.category?.description" class="purchase-form__attention">
      <i class="bx bx-info-circle text-primary" />
      <p>{{ form.category.description }}</p>
    </div>
    <b-form-group
      label="Description"
      label-for="description"
      class="required"
    >
      <textarea
        type="text"
        v-model="form.description"
        id="description"
        rows="3"
        class="form-control"
        :class="{ 'is-invalid': $v.form.description.$error }"
      />
    </b-form-group>
    <b-form-group
      v-if="!isRequestType"
      label="Link"
      label-for="link"
    >
      <b-form-input
        id="link"
        v-model="form.link"
      />
    </b-form-group>
    <b-form-group
      :label="isBimicoinCategorySelected ? 'Amount' : 'Price'"
      label-for="price"
      :class="{ required: $v.form.price }"
      label-class="mb-0"
    >
      <div
        v-if="isCompensationCategory"
        class="purchase-form__price-description text-secondary"
      >
        Please indicate the requested amount as the price, which should be <span class="text-danger">50% of the receipt.</span>
      </div>
      <div class="d-flex align-items-center" style="gap: 20px">
        <div class="w-50">
          <b-form-input
            id="price"
            v-model="form.price"
            type="number"
            min="0"
            :class="{ 'is-invalid': $v.form.price?.$error }"
            lazy-formatter
            :formatter="formatNumberInputValue"
          />
        </div>
        <multiselect
          v-if="!isBimicoinCategorySelected"
          v-model="form.currency"
          id="currency"
          class="app-multiselect w-50"
          :options="currencies"
          :show-labels="false"
          :class="{ 'is-invalid': $v.form.currency?.$error }"
          placeholder="Choose Currency"
        />
        <div
            class="d-flex justify-content-between w-50"
            v-else
        >
          <span>USD</span>
          <p class="mb-0"><span class="font-weight-bold">Balance: </span>{{ bimiCoinBalance }} Coins</p>
        </div>
      </div>
    </b-form-group>
    <attachments-manager
      v-model="form.files"
      :attachments="attachments"
      :required="isFilesRequired"
      @onDelete="handleDeleteAttachment"
    />
    <div v-if="$v.form.files?.$error" class="text-danger">Files is required</div>
    <template slot="modal-footer">
      <div class="d-flex justify-content-end">
        <div
          v-b-tooltip.hover
          tabindex="0"
          :disabled="!isSubmitButtonDisabled"
          :title="!isSubmitButtonDisabled ? '' : 'Unfortunately, this category is not available during the closing period of the month. Try to create on the 1st of the next month.'"
        >
          <b-button
            variant="primary"
            class="purchase-form__submit-button"
            :disabled="isSubmitButtonDisabled"
            @click="handleSubmit"
          >
            {{ isCreateMode ? 'Add' : 'Save' }}
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { FORM_MODE } from '@/utils/constants';
import { formatNumber } from '@/utils/numbers';
import { REQUISTION_TYPES } from '@/utils/constants';
import AttachmentsManager from './attachments-manager.vue';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { REQUEST_CATEGORIES } from '@/utils/constants';

export default {
  components: { AttachmentsManager },
  props: {
    value: Boolean,
    mode: String,
    initialData: Object
  },
  data() {
    return {
      form: {
        category: null,
        description: '',
        link: '',
        type:  { id: REQUISTION_TYPES.REQUEST, label: 'Request' },
        price: null,
        currency: '',
        files: [],
        deleteFileIds: []
      },
      typeOptions: [
        { id: REQUISTION_TYPES.REQUEST, label: 'Request' },
        { id: REQUISTION_TYPES.PURCHASE, label: 'Purchase' }
      ]
    }
  },
  validations() {
    const form = {
      type: { required },
      category: {
        required,
        requiredUniqCategory: (model) => !this.excludeCategories.some(el => el.title.toLocaleLowerCase() === model?.title?.toLocaleLowerCase())
      },
      description: { required },
    };

    if (!this.isLearningOrEquipmentCategorySelected && !this.isBimicoinCategorySelected) {
      form.price = { required, minValue: minValue(1) };
      form.currency = { required };
    }

    if (this.isBimicoinCategorySelected) {
      form.price = { required, minValue: minValue(1), maxValue: maxValue(this.bimiCoinBalance) };
      form.currency = { required };
    }

    if (this.isRequestType
        && !this.isLearningOrEquipmentCategorySelected
    ) {
      form.files = { isNotEmpty: (value) => {
        return !((this.attachments.length === 0 || this.form.deleteFileIds.length === this.attachments.length) && value.length === 0)
      }}
    }

    return { form };
  },
  methods: {
    handleSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.$emit('onSubmit', this.form);
        this.isOpen = false;
      }
    },
    handleHideModal() {
      this.form.type = { id: REQUISTION_TYPES.REQUEST, label: 'Request' };
      this.form.category = null;
      this.form.description = '';
      this.form.link = '';
      this.form.price = null;
      this.form.currency = '';
      this.form.files = [];
      this.form.deleteFileIds = [];
      this.$v.$reset();
    },
    fillFormData() {
      const category = this.categories.find(({ id }) => id === this.initialData?.purchase_category?.id);
      const type = category ? this.typeOptions.find(({ id }) => id === category.type) : { id: REQUISTION_TYPES.REQUEST, label: 'Request' };
      this.form.type = type;
      this.form.category = category ?? null;
      this.form.description = this.initialData?.description ?? '';
      this.form.link = this.initialData?.link ?? '';
      this.form.price = this.initialData?.price ?? null;
      this.form.currency = this.initialData?.currency ?? '';
      this.form.files = [];
      this.form.deleteFileIds = [];
    },
    formatNumberInputValue(value) {
      if (this.isBimicoinCategorySelected) {
        return Math.round(value);
      }
      return formatNumber(value);
    },
    handleDeleteAttachment(id) {
      this.form.deleteFileIds.push(id);
    },
    handleTypeChange() {
      this.form.category = null;
    }
  },
  computed: {
    currencies() {
      return ['USD', 'EUR', 'AED', 'UZS', 'GBP', 'RUB', 'KZT', 'MXN', 'TRY', 'CNY', 'AMD', 'BYN', 'GEL', 'UAH'];
    },
    categories() {
      return this.$store.state.purchases.categories
    },
    filteredCategories() {
      return this.categories.filter(({ available, type }) => available && type === this.form.type.id);
    },
    bimiCoinBalance() {
      return this.$store.state.balanceDetails?.balance ?? 0
    },
    isOpen: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    },
    isCreateMode() {
      return this.mode === FORM_MODE.CREATE;
    },
    attachments() {
      return this.initialData?.attachments ?? [];
    },
    isRequestType() {
      return this.form.type.id === REQUISTION_TYPES.REQUEST;
    },
    currentCategoryNameInLowerCase() {
      return (this.form.category?.title ?? '').toLowerCase();
    },
    isLearningOrEquipmentCategorySelected() {
      return [
        REQUEST_CATEGORIES.EQUIPMENT_AND_HARDWARE.toLowerCase(),
        REQUEST_CATEGORIES.LEARNING_AND_DEVELOPMENT.toLowerCase(),
        REQUEST_CATEGORIES.OFFICE_MAINTENANCE.toLowerCase()
      ].includes(this.currentCategoryNameInLowerCase);
    },
    isBimicoinCategorySelected() {
      return [REQUEST_CATEGORIES.BIMICOIN_EXPENDITURE.toLowerCase()].includes(this.currentCategoryNameInLowerCase);
    },
    isCompensationCategory() {
      return [
        REQUEST_CATEGORIES.MEDICAL_EXPENSES.toLowerCase(),
        REQUEST_CATEGORIES.LANGUAGE_COURSES.toLowerCase(),
        REQUEST_CATEGORIES.FITNESS_AND_SPORT.toLowerCase(),
        REQUEST_CATEGORIES.BOOKS_AND_PUBLICATIONS.toLowerCase()
      ].includes(this.currentCategoryNameInLowerCase);
    },
    isFilesRequired() {
      return this.isRequestType && !this.isLearningOrEquipmentCategorySelected;
    },
    isSubmitButtonDisabled() {
      const isBimiCoinCategory = this.currentCategoryNameInLowerCase === REQUEST_CATEGORIES.BIMICOIN_EXPENDITURE.toLowerCase()
      if (this.isCompensationCategory || isBimiCoinCategory) {
        const day = this.$moment().format('DD');
        return ['28', '29', '30', '31'].includes(day);
      }
      return false;
    },
    excludeCategories() {
      return this.$store.state.purchases.excludeCategories
    }
  },
  watch: {
    isOpen(value) {
      if (value && this.initialData && !this.isCreateMode) {
        this.fillFormData();
      }
    },
    'form.category': function() {
      if (this.isBimicoinCategorySelected) {
        this.form.currency = 'USD';
      }
    }
  }
}
</script>

.<style lang="scss" scoped>
.app-multiselect {
  height: 40px;
}
.purchase-form {
  &__attention {
    gap: 10px;
    display: flex;
    padding: 10px;
    color: #343A40;
    border-radius: 4px;
    margin-bottom: 15px;
    background: #EFF2F7;

    i {
      font-size: 24px;
    }
  }
  &__submit-button {
    width: 94px;
  }
  &__price-description {
    font-size: 12px;
  }
}
@media (min-width: 576px) {
  :deep(.modal-dialog) {
    max-width: 580px;
  }
}
</style>
