<template>
  <layout :loading="loading">
    <div class="purchases">
      <div class="purchases__header d-flex align-items-center justify-content-between">
        <h1 class="title-1">Requisitions</h1>
        <div class="d-flex align-items-center">
          <router-link :to="{ name: 'requisitions-faq' }" class="mr-3">
            <div class="d-flex align-items-center font-size-16">
              FAQ
              <i class="bx bx-help-circle font-size-16 ml-1" />
            </div>
          </router-link>
          <b-button
            class="mr-2"
            variant="success"
            @click="openCreateModal"
          >
            <div class="d-flex align-items-center">
              <i class="bx bx-plus font-size-18 mr-2" />
              Add Requisition
            </div>
          </b-button>
          <b-button
            v-if="tabIndex !== 0"
            class="mr-2"
            variant="warning"
            @click="exportToCsv"
          >
            <div class="d-flex align-items-center">
              <i class="bx bxs-download font-size-16 mr-2" />
              Export to CSV
            </div>
          </b-button>
          <b-button
            v-if="$hasAccess(['admin'])"
            variant="primary"
            class="purchases__settings-button"
            @click="openRulesPage"
          >
            <i class="bx bxs-cog" />
          </b-button>
        </div>
      </div>
      <div class="purchases__content">
        <b-tabs
          v-model="tabIndex"
          nav-class="nav-tabs-custom purchases__tabs"
        >
          <b-tab>
            <template v-slot:title>
              <span class="font-size-16">My Requisition</span>
            </template>
          </b-tab>
          <b-tab :title-item-class="getTabClass('waiting_for_approval')">
            <template v-slot:title>
              <span class="font-size-16">Waiting for approval</span>
              <span
                v-if="tabs.waiting_for_approval"
                class="nav-link__badge"
              >
                {{ tabs.waiting_for_approval }}
              </span>
            </template>
          </b-tab>
          <b-tab :title-item-class="getTabClass('waiting_for_payment')">
            <template v-slot:title>
              <span class="font-size-16">Waiting for payment</span>
              <span
                v-if="tabs.waiting_for_payment"
                class="nav-link__badge"
              >
                {{ tabs.waiting_for_payment }}
              </span>
            </template>
          </b-tab>
          <b-tab :title-item-class="getTabClass('waiting_for_execution')">
            <template v-slot:title>
              <span class="font-size-16">Waiting for execution</span>
              <span
                v-if="tabs.waiting_for_execution"
                class="nav-link__badge"
              >
                {{ tabs.waiting_for_execution }}
              </span>
            </template>
          </b-tab>
          <b-tab :title-item-class="getTabClass('all')">
            <template v-slot:title>
              <span class="font-size-16">All Requisitions</span>
            </template>
          </b-tab>
        </b-tabs>
        <b-card
          class="purchases__container"
          :bodyClass="purchases.length ? 'p-0' : 'empty p-0'"
        >
          <b-table
            :items="purchases"
            :fields="fields"
            :no-border-collapse="true"
            class="purchases__table"
            @row-clicked="handleRowClick"
          >
            <template #head(employee)="{ label }">
              <template v-if="tabIndex === 4">
                <multiselect
                  v-model="filters.employees"
                  multiple
                  :options="employees"
                  :searchable="true"
                  :show-labels="false"
                  label="name"
                  track-by="uid"
                  placeholder="Employee"
                  class="app-multiselect"
                >
                  <template #selection="{ values, search, isOpen }">
                    <span
                      v-if="values.length"
                      v-show="!isOpen"
                      class="multiselect__single"
                    >
                      {{ values.length }} options selected
                    </span>
                  </template>
                </multiselect>
                <i
                  v-if="filters.employees.length"
                  class="bx bxs-x-circle"
                  @click="filters.employees = []"
                />
              </template>
              <span v-else>{{ label }}</span>
            </template>
            <template #head(purchase_category)="{ label }">
              <dropdown-select
                v-if="tabIndex === 4"
                v-model="filters.categories"
                :options="categories"
                valueKey="id"
                label="title"
                multiple
                allOptionsText="All Categories"
              />
              <span v-else>{{ label }}</span>
            </template>
            <template #head(created_at)="{ label }">
              <sort-label
                :sort="sort"
                fieldName="created_at"
                @click.native="handleSortChange('created_at')"
              >
                {{ label }}
              </sort-label>
            </template>
            <template #head(price)="{ label }">
              <sort-label
                :sort="sort"
                fieldName="price"
                class="justify-content-end"
                @click.native="handleSortChange('price')"
              >
                {{ label }}
              </sort-label>
            </template>
            <template #head(status)>
              <dropdown-select
                v-model="filters.statuses"
                :options="statuses"
                valueKey="id"
                label="title"
                class="w-auto"
                multiple
                allOptionsText="All Statuses"
              />
            </template>
            <template #cell(purchase_category.type)="{ value }">
              <span class="text-capitalize">{{ value }}</span>
            </template>
            <template #cell(description)="{ value, item }">
              <div class="d-flex align-items-center">
                <span>{{ value }}</span>
                <a
                  v-if="item.link"
                  :href="item.link"
                  target="_blank"
                  class="font-size-16 ml-2"
                >
                  <i class="bx bx-link-alt" />
                </a>
              </div>
            </template>
            <template #cell(employee)="{ value }">
              <div class="d-flex align-items-center">
                <b-avatar
                  variant="info"
                  size="30px"
                  v-b-tooltip.hover
                  :title="value.name"
                  :text="getNameInitials(value.name)"
                  :src="value.photo"
                />
                <span class="ml-2">{{ value.name }}</span>
              </div>
            </template>
            <template #cell(purchase_category)="{ value }">
              <div>
                {{ value.title }}
              </div>
            </template>
            <template #cell(created_at)="{ value }">
              {{ $moment(value).format('DD.MM.YYYY') }}
            </template>
            <template #cell(price)="{ item }">
              {{ numberWithSpaces(item.price) }} {{ item.currency }}
            </template>
            <template #cell(status)="{ value }">
              <purchase-status-badge :status="value" />
            </template>
            <template #cell(purchase_statuses)="{ item }">
              <div
                  class="d-flex justify-content-end"
                  style="gap: 4px"
                  v-if="item.status !== 'done' && item.status !== 'declined'"
              >
                <approver-icon
                  v-for="approver in getAllApprovers(item)"
                  :key="approver.id"
                  :approver="approver"
                />
                <approver-icon :approver="parseApprover(item, item.purchase_category.payment_employee, ['paid', 'declined'])" />
                <approver-icon :approver="parseApprover(item, item.purchase_category.executior_employee, ['done', 'declined'])" />
              </div>
              <div
                  class="d-flex justify-content-end"
                  style="gap: 4px"
                  v-else
              >
                <approver-icon
                    v-for="approver in getAllFromPurchaseStatuses(item)"
                    :key="approver.id"
                    :approver="approver"
                />
              </div>
            </template>
            <template #cell(actions)="{ item }">
              <b-dropdown
                v-if="canMakeActions(item)"
                right
                class="purchases__dropdown w-100"
              >
                <template #button-content>
                  <i class="bx bx-dots-vertical-rounded" />
                </template>
                <b-dropdown-item @click="onEditIconClick(item)">
                  <div class="d-flex align-items-center">
                    <i class="bx bx-pencil font-size-16" />
                    Edit
                  </div>
                </b-dropdown-item>
                <b-dropdown-item @click="onDeleteIconClick(item)">
                  <div class="d-flex align-items-center">
                    <i class="bx bx-trash font-size-16" />
                    Delete
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div
            v-if="!purchases.length"
            class="purchases__empty-list"
          >
            <img src="@/assets/images/сhecklist.svg" alt="">
            <div>No Purchases</div>
          </div>
        </b-card>
      </div>
      <pagination
        :page="page"
        :limit="limit"
        :totalRecordsCount="totalRecordsCount"
        @onPageChange="handlePageChange"
        @onLimitChange="handleLimitChange"
      />
      <approve-modal
        v-model="isApproveModalOpen"
        :mode="approveModalMode"
        :purchase="currentPurchase"
        @onSubmit="handleApproveOrDeclinePurchase"
        @onPay="handlePayOrExecute($event, 'paid')"
        @onExecute="handlePayOrExecute($event, 'done')"
        @onDownloadAttachment="handleDownloadAttachment"
      />
      <purchase-modal
        v-model="isModalOpen"
        :mode="mode"
        :initialData="formInitialData"
        @onSubmit="handleSubmitForm"
      />
    </div>
  </Layout>
</template>

<script>
import { mapActions } from 'vuex';
import { FORM_MODE } from '@/utils/constants';
import { getNameInitials } from '@/utils/user';
import { numberWithSpaces } from '@/utils/numbers';
import SortLabel from '@/components/sort-label.vue';
import Pagination from '@/components/pagination.vue';
import DropdownSelect from '@/components/dropdown-select.vue';
import ApproverIcon from '@/components/purchases/approver-icon.vue';
import ApproveModal from '@/components/purchases/approve-modal.vue';
import PurchaseModal from '@/components/purchases/purchase-modal.vue';
import PurchaseStatusBadge from '@/components/purchases/purchase-status-badge.vue';
import { convertObjectToFormData } from '../../../utils/converters';

const PURCHASE_TYPES = [null, 'waiting_for_approval', 'waiting_for_payment', 'waiting_for_execution', 'all'];
const PURCHASE_MODAL_MODE = ['view', 'approve', 'pay', 'execute', 'view']

const TABLE_FIELDS = [
  { key: 'id', label: '№', thStyle: { width: '80px' }, tabs: [0, 1, 2, 3, 4] },
  { key: 'employee', label: 'Employee', thStyle: { width: '250px' }, tabs: [1, 2, 3, 4] },
  { key: 'purchase_category', label: 'Category', thStyle: { width: '204px' }, tabs: [0, 1, 2, 3, 4], tdClass: 'purchases__category' },
  { key: 'purchase_category.type', label: 'Type', thStyle: { width: '100px' }, tabs: [0, 1, 2, 3, 4] },
  { key: 'description', tabs: [0, 1, 2, 3, 4], thStyle: { width: '300px' }, tdClass: 'purchases__description' },
  { key: 'created_at', label: 'Date', thStyle: { width: '112px' }, tabs: [0, 1, 2, 3, 4] },
  { key: 'price', tdClass: 'text-right', thStyle: { width: '115px', textAlign: 'right' }, tabs: [0, 1, 2, 3, 4] },
  { key: 'status', tdClass: 'text-right', thClass: 'text-right', thStyle: { width: '150px' }, tabs: [0, 4] },
  { key: 'purchase_statuses', label: 'Stakeholders', thClass: 'text-right', thStyle: { width: '270px' }, tdClass: 'purchases__stakeholders', tabs: [0, 1, 2, 3, 4] },
  { key: 'actions', label: '', tdClass: 'purchases__actions', thStyle: { width: '44px' }, tabs: [0] }
];

export default {
  components: {
    SortLabel,
    Pagination,
    ApproverIcon,
    ApproveModal,
    PurchaseModal,
    DropdownSelect,
    PurchaseStatusBadge
  },
  data() {
    return {
      page: 1,
      limit: 60,
      tabIndex: 0,
      loading: false,
      isModalOpen: false,
      formInitialData: null,
      mode: FORM_MODE.CREATE,
      isApproveModalOpen: false,
      currentPurchase: null,
      approveModalMode: 'approve',
      filters: {
        employees: [],
        statuses: [{ id: null, title: 'All Statuses' }],
        categories: [{ id: null, title: 'All Categories'}]
      },
      statuses: [
        { id: 'done', title: 'Done' },
        { id: 'declined', title: 'Declined' },
        { id: 'paid', title: 'Paid' },
        { id: 'approved', title: 'Approved' },
        { id: 'waiting_for_approval', title: 'Waiting for approval' }
      ],
      sort: {
        field: 'created_at',
        direction: 'desc',
      },
      disableFetchingAfterFiltersCleared: false
    };
  },
  async mounted() {
    if (this.checkUrlParams()) {
      await this.openModalByUrlParams()
    } else {
      await this.fetchPurchases();
    }
    this.getPurchaseCategories();
    this.$store.dispatch('employees/getAllEmployees')
    this.$store.dispatch('balanceDetails/getBalanceRequest')
  },
  methods: {
    getNameInitials,
    numberWithSpaces,
    ...mapActions('purchases', [
      'getPurchases',
      'deletePurchase',
      'clearPurchases',
      'updatePurchase',
      'createPurchase',
      'exportToCsvPurchase',
      'uploadPurchaseFiles',
      'deletePurchaseFiles',
      'downloadPurchaseFiles',
      'getPurchaseCategories',
      'updatePurchaseStatus',
    ]),
    ...mapActions('notifications', ['getNotificationChecksAndCounts']),
    openRulesPage() {
      this.$router.push({ name: 'requisitions-categories' });
    },
    checkUrlParams() {
      const { type, id } = this.$route.query
      return type && id
    },
    openCreateModal() {
      this.isModalOpen = true;
      this.formInitialData = null;
      this.mode = FORM_MODE.CREATE;
    },
    async openModalByUrlParams() {
      const { id, type } = this.$route.query;
      const isHasType = PURCHASE_TYPES.some(el => el === type)
      const findedIndex = PURCHASE_TYPES.findIndex(el => el === type)
      this.loading = true
      await this.getPurchases({
        ...this.requestParams,
        per_page: isHasType ? 100 : this.limit,
        type: isHasType
            ? type
            : PURCHASE_TYPES[this.tabIndex]
      });
      this.loading = false
      const item = this.purchases.find((item) => item.id === +id);
      this.tabIndex = findedIndex
      if (item) {
        this.handleOpenApproveModal(item, PURCHASE_MODAL_MODE[this.tabIndex]);
      }
      this.$router.replace({ name: 'requisitions' })
    },
    getTabClass(key) {
      return this.tabs?.[key] ? '' : 'd-none';
    },
    onEditIconClick(data) {
      this.formInitialData = data;
      this.mode = FORM_MODE.EDIT;
      this.isModalOpen = true;
    },
    onDeleteIconClick(item) {
      this.$bvModal.msgBoxConfirm('Delete this item?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(async (value) => {
          if (value) {
            this.loading = true;
            await this.deletePurchase(item.id);
            await this.fetchPurchases();
            this.loading = false;
          }
        });
    },
    async handleUploadFiles(files, attachableId) {
      await this.uploadPurchaseFiles(convertObjectToFormData({
        files,
        type: 'purchase',
        attachable_id: attachableId
      }));
    },
    async handleSubmitForm({ category, files, deleteFileIds, ...data }) {
      this.loading = true;
      try {
        let purchaseId = this.formInitialData?.id;
        if (this.mode === FORM_MODE.CREATE) {
          const response = await this.createPurchase({
            ...data,
            purchase_category_id: category.id,
          });
          purchaseId = response?.data?.id;
        } else {
          await this.updatePurchase({
            id: purchaseId,
            data: {
              ...data,
              purchase_category_id: category.id,
            }
          });
        }
        if (files.length) {
          await this.handleUploadFiles(files, purchaseId);
        }
        if (deleteFileIds.length) {
          await this.deletePurchaseFiles({ ids: deleteFileIds })
        }

        await this.fetchPurchases();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async handleDownloadAttachment({ id, file_name: fileName }) {
      this.loading = true;
      await this.downloadPurchaseFiles({ id, fileName });
      this.loading = false;
    },
    handleOpenApproveModal(row, mode = 'view') {
      this.currentPurchase = row;
      this.approveModalMode = mode;
      this.isApproveModalOpen = true;
    },
    async handleUpdatePurchaseStatus(data) {
      this.loading = true;
      await this.updatePurchaseStatus(data);
      await this.fetchPurchases();
      this.loading = false;
    },
    handleApproveOrDeclinePurchase({ comment, status }) {
      this.handleUpdatePurchaseStatus({
        status,
        comment,
        purchase_id: this.currentPurchase?.id
      });
    },
    handlePayOrExecute(item, status) {
      this.$bvModal.msgBoxConfirm(`Do yow want to ${status === 'paid' ? 'pay' : 'execute'}?`, {
        title: 'Please Confirm',
        okVariant: 'success',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then((value) => {
          if (value) {
            this.isApproveModalOpen = false;
            this.handleUpdatePurchaseStatus({ purchase_id: item.id, status });
          }
        });
    },
    canMakeActions(purchase) {
      return !purchase.purchase_statuses.some(({ status }) => status === 'approved')
        && purchase.status !== 'done'
        && purchase.status !== 'approved'
        && purchase.status !== 'declined';
    },
    isApproved(status) {
      return status === 'approved' || status === 'done' || status === 'paid';
    },
    isDeclined(status) {
      return status === 'declined';
    },
    async fetchPurchases() {
      this.loading = true;
      await this.getPurchases(this.requestParams);
      this.getNotificationChecksAndCounts();
      this.loading = false;
    },
    handlePageChange(page) {
      this.page = page;
      this.fetchPurchases();
    },
    handleLimitChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.fetchPurchases();
    },
    getApproverStatus(statuses, uid, status) {
      const findEmployeeByUidAndStatus = (uid, status) => {
        return (item) => item.employee_uid === uid && item.status === status;
      }
      return statuses.filter(
        findEmployeeByUidAndStatus(uid, status)
      ).length ? status : null
    },
    parseApprover(purchase, employee, statuses) {
      return {
        ...employee,
        status: statuses.reduce(
          (acc, status) => acc || this.getApproverStatus(purchase.purchase_statuses, employee.uid, status),
          null
        )
      }
    },
    getAllFromPurchaseStatuses(purchase) {
      const { purchase_statuses } = purchase
      return purchase_statuses?.length ? purchase_statuses.map(el => {
        return {
          ...el.employee,
          status: el.status
        }
      }) : []
    },
    getAllApprovers(purchase) {
      const { approvers } = purchase;
      return [
        ...approvers.map((employee) => ({
          ...this.parseApprover(purchase, employee, ['approved', 'declined'])
        })),
      ];
    },
    handleRowClick(item) {
      this.handleOpenApproveModal(item, PURCHASE_MODAL_MODE[this.tabIndex]);
      this.$router.replace({ query: {} });
    },
    clearFilters() {
      this.filters.employees = [];
      this.filters.statuses = [{ id: null, title: 'All Statuses' }];
      this.filters.categories = [{ id: null, title: 'All Categories' }];
    },
    handleSortChange(field) {
      if (this.sort.field === field) {
        if (this.sort.direction === 'asc') {
          this.sort.direction = 'desc';
        } else if (this.sort.direction === 'desc') {
          this.sort.field = '';
          this.sort.direction = '';
        }
      } else {
        this.sort.field = field;
        this.sort.direction = 'asc';
      }
    },
    async exportToCsv() {
      this.loading = true;
      await this.exportToCsvPurchase(this.requestParams);
      this.loading = false;
    }
  },
  computed: {
    tabs() {
      return this.$store.state.purchases.tabs;
    },
    purchases() {
      return this.$store.state.purchases.purchases;
    },
    totalRecordsCount() {
      return this.$store.state.purchases.totalItems;
    },
    fields() {
      return TABLE_FIELDS.filter(({ tabs }) => tabs.includes(this.tabIndex));
    },
    user() {
      return this.$store.state.user.user;
    },
    requestSortParam() {
      return this.sort.field
        ? { [`sort[${this.sort.field}]`]: this.sort.direction }
        : {};
    },
    requestParams() {
      return {
        page: this.page,
        per_page: this.limit,
        type: PURCHASE_TYPES[this.tabIndex],
        status: this.filters.statuses.filter(({ id }) => id).map(({ id }) => id),
        employee_uid: this.filters.employees.map(({ uid }) => uid),
        purchase_category_id: this.filters.categories.filter(({ id }) => id).map(({ id }) => id),
        ...this.requestSortParam
      }
    },
    categories() {
      return this.$store.state.purchases.categories;
    },
    employees() {
      return this.$store.state.employees.allEmployees;
    },
  },
  watch: {
    tabIndex() {
      this.page = 1;
      this.disableFetchingAfterFiltersCleared = true;
      this.clearFilters();
      this.clearPurchases();
      this.fetchPurchases();
    },
    filters: {
      handler() {
        if ((this.tabIndex === 4 || this.tabIndex === 0) && !this.disableFetchingAfterFiltersCleared) {
          this.page = 1;
          this.fetchPurchases();
        }
        if (this.disableFetchingAfterFiltersCleared) {
          this.disableFetchingAfterFiltersCleared = false;
        }
      },
      deep: true
    },
    sort: {
      async handler() {
        this.loading = true;
        await this.fetchPurchases();
        this.loading = false;
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.nav-tabs) {
  padding-left: 15px;
  .nav-link {
    padding: 8px 20px;
    position: relative;

    &__badge {
      top: 0px;
      right: 0px;
      color: #fff;
      padding: 0 5px;
      min-width: 19px;
      font-size: 13px;
      font-style: normal;
      text-align: center;
      font-weight: 500;
      line-height: 16px;
      position: absolute;
      border-radius: 10px;
      background: #DC3545;
    }

    &.active {
      background-color: transparent;
    }
  }
}
.purchases {
  display: flex;
  height: calc(100vh - 40px);
  flex-direction: column;
  &__header {
    margin-bottom: 20px;
  }
  &__settings-button {
    width: 36.53px;
    height: 36.53px;
    position: relative;

    i {
      top: 50%;
      left: 50%;
      font-size: 16px;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  &__container {
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    .card-body {
      height: 0px;
      flex-grow: 1;
      padding: 20px 0px;
      overflow: scroll;

      &.empty {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &__table {
    table-layout: fixed;
    margin-bottom: 0px;

    :deep(th) {
      color: #2A3042;
      font-weight: 500;
    }
    :deep(th), :deep(td) {
      border-top: unset;
      padding: 12px 20px;
    }
  }
  &__empty-list {
    height: 0px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    div {
      color: #000;
      font-size: 16px;
      margin-top: 10px;
      font-weight: 500;
      line-height: 25px;
      font-style: normal;
    }
  }
  &__dropdown {
    width: 10px;
    height: 24px;
    position: relative;

    :deep(.btn) {
      padding: 0;
      border: none !important;
      background-color: transparent !important;
    }

    .bx-dots-vertical-rounded {
      top: 50%;
      left: 50%;
      font-size: 24px;
      color: #292f42;
      position: absolute;
      transform: translate(-50%, -50%);
    }

    :deep(.dropdown-item) {
      padding: 5px 20px;
      i {
        margin-right: 10px;
      }
    }
  }
  .bxs-x-circle {
    top: 50%;
    right: 40px;
    opacity: 0.3;
    cursor: pointer;
    position: absolute;
    color: #1B212D;
    transform: translateY(-50%);
  }
  .app-multiselect {
    height: 20px;
    min-height: 20px;
    float: right;
    width: calc(100% - 30px);

    :deep(.multiselect__tags) {
      height: 20px;
      border: none !important;
      min-height: 20px !important;
      padding: 0px 40px 0px 10px !important;
    }
    :deep(.multiselect__single),
    :deep(.multiselect__placeholder) {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      color: #2A3042;
    }
    :deep(.multiselect__select) {
      width: 25px;
    }
  }
}
:deep(.purchases__stakeholders) {
  padding: 15px 20px !important;
}
:deep(.purchases__actions) {
  text-align: right;
  padding: 15px 20px 15px 0px !important;
}
:deep(.purchases__description) {
  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a {
    line-height: normal;
  }
}
:deep(.purchases__category) {
  div {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
:deep(.table th) {
  padding: 20px;
  position: relative;
  border-bottom: none;
  background-color: #EFF2F7 !important;
}
:deep(.table td) {
  vertical-align: middle;
  padding: 20px;
}
:deep(.table tbody tr) {
  cursor: pointer;
  &:hover {
    background: #EFF2F7;
  }
}
</style>
