<template>
  <div class="files-uploader">
    <span
      class="files-uploader__title"
      :class="{ required: required }"
    >
      File upload
    </span>
    <div class="files-uploader__list">
      <div
        v-for="file in attachmentsList"
        :key="file.id"
        class="files-uploader__item"
      >
        <b-form-input :value="file.file_name" readonly size="sm"/>
        <b-button
          size="sm"
          variant="light"
          class="files-uploader__delete-button"
          @click="handleDeleteAttachment(file.id)"
        >
          <i class="bx bxs-trash" />
        </b-button>
      </div>
      <div
        v-for="(file, index) in files"
        :key="file.name + index"
        class="files-uploader__item"
      >
        <b-form-input :value="file.name" readonly size="sm"/>
        <b-button
          size="sm"
          variant="light"
          class="files-uploader__delete-button"
          @click="deleteFile(index)"
        >
          <i class="bx bxs-trash" />
        </b-button>
      </div>
    </div>
    <p class="files-uploader__description text-secondary mb-1">
      Upload a receipt, invoice or any document confirming expenses within this request<br />
      Allowed size formats: JPEG, PNG or PDF under 50 MB
    </p>
    <b-button
      variant="light"
      class="files-uploader__button"
    >
      <i class="bx bx-plus font-size-16" /> Add files
      <label for="file-upload" class="m-0">
        <input
          id="file-upload"
          type="file"
          multiple
          class="files-uploader__file-input"
          accept="image/png, image/jpeg, application/pdf"
          @change="handleInputChange"
        />
      </label>
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    attachments: {
      type: Array,
      default: () => []
    },
    required: Boolean
  },
  data() {
    return {
      deleteIds: []
    }
  },
  methods: {
    handleInputChange(event) {
      const files = [];
      [...event.target.files].forEach((file) => {
        if (file.size / 1024 / 1024 < 50) {
          files.push(file);
        } else {
          this.$showErrorToast(`Unable to upload File due to 50 MB size limit (${file.name})`, 'File size limit');
        }
      });
      this.files = [...this.files, ...files];
    },
    deleteFile(index) {
      const list = [...this.files];
      list.splice(index, 1);
      this.files = [...list];
    },
    handleDeleteAttachment(id) {
      this.deleteIds.push(id);
      this.$emit('onDelete', id);
    }
  },
  computed: {
    files: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    },
    attachmentsList() {
      return this.attachments.filter(({ id }) => !this.deleteIds.includes(id));
    }
  }
}
</script>

<style lang="scss" scoped>
.files-uploader {
  &__title {
    font-weight: 500;
    line-height: 20px;
    &.required::after {
      content: '*';
      color:#f46a6a;
    }
  }
  &__description {
    font-size: 12px;
  }
  &__button {
    display: flex;
    column-gap: 10px;
    position: relative;
    align-items: center;
    padding: 6px 10px;

    label {
      inset: 0;
      cursor: pointer;
      position: absolute;
    }
  }
  &__file-input {
    display: none;
  }
  &__list {
    display: flex;
    row-gap: 10px;
    flex-direction: column;
  }
  &__item {
    display: grid;
    column-gap: 15px;
    grid-template-columns: 1fr 36px;
  }
  &__delete-button {
    position: relative;

    i {
      top: 50%;
      left: 50%;
      font-size: 16px;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
